.no-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.str-chat__channel-list-messenger-react__main {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

.str-chat__channel-list-messenger-react__main::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.rnf-notification-feed {
  width: 100% !important;
}

.rnf-notification-feed__container {
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
}

.rnf-notification-feed__container::-webkit-scrollbar {
  display: none !important;
}

.rnf-notification-cell__unread-dot,
.rnf-notification-cell__timestamp {
  display: none !important;
}

.rnf-notification-cell__child-content {
  margin: 0 !important;
}

.rnf-notification-cell__content {
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-height: 4.5em !important; /* Approximately 3 lines of text */
  line-height: 1.5em !important;
}
