html,
body,
#root {
  height: 100%;
}
body {
  margin: 0;
}
#root {
  display: flex;
}

.str-chat__channel-list {
  width: 100%;
  border: 0 !important;
}
.str-chat__channel {
  width: 100%;
}
.str-chat__thread {
  width: 45%;
}

.str-chat * {
  box-sizing: border-box;
  word-break: break-word;
}

.str-chat__channel-header {
  border-bottom: 1px solid rgba(18, 43, 70, 0.1) !important;
  height: 80px !important;
  min-height: 80px !important;
  font-family: 'Poppins' !important;
}

.str-chat__channel-header-title {
  font-family: 'Poppins' !important;
}

.str-chat__channel-header-info {
  font-family: 'Poppins' !important;
}

.image-gallery {
  height: 80%;
  width: 80%;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 25px;
  width: 25px;
}

.str-chat__date-separator {
  font-size: 14px;
  color: rgba(18, 43, 70, 0.5) !important;
  font-family: 'Poppins' !important;
  padding: 1rem !important;
}

.str-chat__date-separator-date {
  background-color: #f6f7fb;
  padding: 10px;
  border-radius: 80px;
  margin-right: 0px !important;
}

.str-chat__date-separator-line {
  border-top: 1.5px dashed #d3daee !important;
  background-image: linear-gradient(to right, #d3daee 33%, rgba(255, 255, 255, 0) 0%);
  background-size: 8px 2px; /* Ajusta 8px para espacio horizontal, 2px para grosor */
  border: none; /* Elimina cualquier borde por defecto */
  height: 1px; /* Grosor del hr */
  background: none !important; /* Sin fondo continuo */
  margin-right: 0px !important;
}

.str-chat__unread-messages-separator {
  background-color: #f6f7fb !important;
  border-radius: 10px;
  color: rgba(18, 43, 70, 0.5) !important;
  font-family: 'Poppins-Medium' !important;
  text-transform: none !important;
}

.str-chat__load-more-button__button {
  font-family: 'Poppins' !important;
  border-radius: 8px !important;
  padding: 8px 16px !important;
  width: 200px !important;
  height: 40px !important;
  background-color: rgb(247 7 99 / 0.05) !important;
  color: rgb(247 7 99 / 1) !important;
}
