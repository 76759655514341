.exhibitorListTable {
  min-height: 58vh !important;
}

div.clinic-select__value-container {
  gap: 10px;
  padding: 10px;
}

div.clinic-select__menu-list:first-child {
  color: black;
  font-size: 14px;
}

div.clinic-select__control {
  min-height: 56px;
  border-radius: 8px;
}
div.clinic-select__multi-value__label {
  padding: 7px;
}
div.clinic-select__multi-value {
  background: transparent;
  border: 1px solid #122b46;
  border-radius: 100px;
  overflow: hidden;
  margin: 0;
}

div.clinic-select__multi-value__remove:hover {
  background: #f7076310;
}

.exhibitorListTable::-webkit-scrollbar {
  display: none;
}

.exhibitorListTable [role='row']:not(:last-child) {
  border-bottom: 1px solid rgb(233, 236, 239) !important;
}

.exhibitorListTable .gocYik {
  border-bottom-color: rgb(233, 236, 239) !important;
}

.exhibitorListTable [role='cell'] {
  padding: 8px 0px !important;
}
