.tw-button-sponsors {
  width: 108px;
  height: 50px;
  border-radius: 10px;
  outline: none;
  -webkit-transition: background 0.4s;
  transition: background 0.4s;
}

.tw-button-sponsors span {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 52px;
  height: 40px;
  border-radius: 10px;
  -webkit-transition: all 0.2s cubic-bezier(0.24, 1.74, 0.92, 0.85);
  transition: all 0.2s cubic-bezier(0.24, 1.74, 0.92, 0.85);
}

.tw-button-sponsors:active span {
  width: 56px;
}

.tw-button-sponsors .btn-text.yes {
  left: 0;
}

.tw-button-sponsors .btn-text.no {
  right: 0;
  color: rgba(18, 43, 104, 0.5);
}

.tw-button-sponsors:active.yes span {
  margin-left: -54px;
}

.tw-button-sponsors.yes span {
  left: 100%;
  margin-left: -56px;
}

.tw-button-sponsors .btn-text {
  width: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
